import { GET_USER_PROFILE } from "./Types";
import axios from "axios";
// import { AccordionActions } from "@material-ui/core";
var token;

export const emailSignin = (userDetails, actions, history) => (dispatch) => {
  console.log("userDetails", history);
  return axios
    .post("https://admin.moneycomeasia.com/api/user_auth/login/", userDetails, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(async (res) => {
      if (res.data.access_token) {
        // console.log('token:', res.data.access_token);
        localStorage.setItem("accessToken", res.data.access_token);
        // console.log(userDetails);
        actions.setSubmitting(false);
        history.push("/dashboard");
      } else if (res.data.detail === "invalid email or password.") {
        alert(res.data.detail);
        actions.setSubmitting(false);
      } else {
        alert(res.data.detail);
        actions.setSubmitting(false);
      }
    })
    .catch((err) => {
      console.log(err);
      actions.setSubmitting(false);
    });
};

export const getUserProfile = () => async (dispatch) => {
  token = localStorage.getItem("accessToken");
  console.log("token,", token);
  return axios
    .get("https://admin.moneycomeasia.com/api/merchant/merchantDetail/", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // var preferenceId = [];
      // var preferenceName = [];
      dispatch({ type: GET_USER_PROFILE, payload: res.data });
      // if (res.data[1].preferences.length > 0) {
      //   for (var pref of res.data[1].preferences) {
      //     preferenceId.push(pref.id);
      //     preferenceName.push(pref.category_name);
      //   }
      //   dispatch({
      //     type: 'GET_USER_PROFILE_PREFERENCE_ID',
      //     payload: preferenceId,
      //   });
      //   dispatch({
      //     type: 'GET_USER_PROFILE_PREFERENCE_NAME',
      //     payload: preferenceName,
      //   });
      // }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
    });
};
