import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Paper,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import whoWeAreImg from "../assets/images/who-we-are-img.svg";
import moneyHandIcon from "../assets/images/money-hand-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "20%",
      // minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
    justifyContent: "center",
    textAlign: "center",
    // minHeight: "100vh",
    padding: "10%",
    paddingTop: 0,
    backgroundColor: theme.palette.primary.main,
    // backgroundImage: `url(${mainBg})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'top'
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "120%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
    width: "100%",
  },
  featuresBox: {
    display: "flex",
    flexDirection: "row",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // alignItems: "center",
      // textAlign: "center",
      justifyContent: "center",
      padding: "10%",
    },
  },
  chatScreenTop: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
    },
  },

  iconContainer: {
    margin: "5%",
  },
}));

const OurFeatures = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} id="features">
      <Grid container className={classes.sectionContainer}>
        <Typography gutterBottom className={classes.mainText}>
          Our Features
        </Typography>
        <Typography>
        Performance-related advertising cost system help merchants optimize every spending.

          {/* Performance-related advertising cost system help merchants optimize every spending.*/}
        </Typography>
        <Paper
          elevation={6}
          style={{
            width: "300px",
            height: "300px",
            margin: "2%",
            borderTopRightRadius: "50px",
            marginTop: "5%",
            padding: "2%",
          }}
        >
          <Typography className={classes.text}>
            {" "}
            Realtime advertisement
            {" "}
          </Typography>
          <Typography className={classes.captionText}>
  
          Merchants publish offers at real-time. (specific time, specific people, Precise location).

          </Typography>
        </Paper>
        <Paper
          elevation={6}
          style={{
            width: "300px",
            height: "300px",
            margin: "2%",
            borderTopRightRadius: "50px",
            marginTop: "5%",
            padding: "2%",
          }}
        >
          <Typography className={classes.text}>
            {" "}
            Group-buy advertisement
            {" "}
          </Typography>
          <Typography className={classes.captionText}>
          Groupon target housing estate or any building, aim at their specific needs.

          </Typography>
        </Paper>
        <Paper
          elevation={6}
          style={{
            width: "300px",
            height: "300px",
            margin: "2%",
            borderTopRightRadius: "50px",
            marginTop: "5%",
            padding: "2%",
          }}
        >
          <Typography className={classes.text}>
            {" "}
            Merchant Request{" "}
          </Typography>
          <Typography className={classes.captionText}>
          Users create / join request, suitable merchant take these group orders.  

          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OurFeatures;
