import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantPoints } from "../../actions/dashboard";

const useStyles = makeStyles((theme) => ({
  paymentCardRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "20px",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
  },
  paymentCardContainer: {
    margin: "10px",
    padding: "20px",
    backgroundImage: "linear-gradient(210deg,#B55FA5, #be62ad75)",
    borderRadius: "20px",
    color: "#fff",
    fontFamily: "Poppins",
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.defaultBlack.main,
    fontSize: "18px",
    marginTop: "10px",
  },
}));

const PaymentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { merchantPoints } = useSelector((state) => state.dashboardReducer);
  React.useEffect(() => {
    dispatch(getMerchantPoints());
  }, [dispatch]);
  return (
    <>
      <div>
        <Typography gutterBottom className={classes.mainText}>
          Your Card
        </Typography>
      </div>
      <div className={classes.paymentCardContainer}>
        <div className={classes.paymentCardRow}>
          <div>
            <div>Point Balance</div>
            <div>{merchantPoints.credit_points}</div>
          </div>
          <div>
            <div>mastercard</div>
          </div>
        </div>
        <div className={classes.paymentCardRow}>
          <div>
            <div>5282 3456 7890 1289</div>
          </div>
          <div>
            <div>09/25</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCard;
