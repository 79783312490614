import React, {useEffect} from "react";
import { makeStyles, Grid, Typography, Button, Hidden, Paper, Modal } from "@material-ui/core";
import { Image } from "semantic-ui-react";
import mainImg from '../assets/images/main-img.svg';
import mainBg from '../assets/images/main-bg.svg';
import LogIn from '../components/LogIn';
import {getUserProfile} from '../actions/user';
import { useDispatch, useSelector } from "react-redux";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";
import CheckoutForm from "../forms/checkoutForm";
import successIcon from '../assets/images/success-icon.png';
import Footer from '../components/Footer';
import LandingPageAppBar from '../components/LandingPageAppBar';

const stripePromise = loadStripe('pk_live_51Mc1GrK32JxNbv5RGXc5vsIaJpbsq17jo7OVRS4CeEVUzzWVH3DDI8QdJbVtEkuoBQNoxD0wjaTVjabUWoqklV4i00ftCE8DlT');

console.log('stripePromise', stripePromise);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#ffffff',
    
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: '20%',
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: '100%'
    },
    justifyContent: 'center',
    // height: '100%',
    minHeight: "100vh",
		padding: '5% 0',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${mainBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    paddingLeft:'5%',
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
      width: '100%',
    },
  },
  ImageSection: {
    display: "flex",
    alignItems: "flex-start",
  },
  DashImage: {
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {
      
    },
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.defaultBlack.main,
    lineHeight: '200%',
  },
  detailsPaper:{
    padding: '5%',
    marginTop: '5%',
    textAlign:'center',
    width: '100%',
  },
  infoGrid:{
    margin: '2%'
  },
  paymentModal:{
    width: '50%',
    margin: 'auto',
    marginTop: '10%',
    textAlign:'center',
    [theme.breakpoints.down("sm")]: {
      width: '80%',
    },
  },
  paymentPaper:{
    padding: '5% 20%',
    alignItems:'center',
    justifyContent:'center',
    [theme.breakpoints.down("sm")]: {
      padding: '10% 5%',
    },
  },
  successModal:{
    width: '50%',
    margin: 'auto',
    marginTop: '10%',
    textAlign:'center',
    [theme.breakpoints.down("sm")]: {
      width: '80%',
    },
  },
  successPaper:{
    padding: '5% 20%',
    alignItems:'center',
    justifyContent:'center',
    [theme.breakpoints.down("sm")]: {
      padding: '10% 5%',
    },
  }
}));

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);


  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  useEffect(()=>{
    dispatch(getUserProfile());
  }, [])

  return (
      <Grid>
        <LandingPageAppBar loggedIn/>
        <Grid className={classes.root}>
          <Grid className={classes.sectionContainer}>
            <Grid item xs={12} md={7} className={classes.FormSection}>
              {store.userReducer.userDetails.status === false ?
                <Paper className={classes.detailsPaper}>
                  <Grid className={classes.infoGrid}>
                    <Typography className={classes.mainText}>No Merchant Found!</Typography>
                    Create your merchant in the app.
                  </Grid>
                </Paper>
               :
               <Paper className={classes.detailsPaper}>
                <Grid className={classes.infoGrid}>
                  <Typography className={classes.mainText}>Merchant Name:</Typography>
                  {store.userReducer.userDetails.business_name}
                </Grid>
                <Grid className={classes.infoGrid}>
                  <Typography className={classes.mainText}>Merchant Contact:</Typography>
                  {store.userReducer.userDetails.business_contact}
                </Grid>
                <Grid className={classes.infoGrid}>
                  <Typography className={classes.mainText}>Current Balance:</Typography>
                  {store.userReducer.userDetails.credit_points}
                </Grid>
                <Button className={classes.button} onClick={toggleModal}>
                  Top Up
                </Button>
              </Paper>}
            </Grid>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              <Image
                // fluid
                src={mainImg}
                className={classes.DashImage}
              />
            </Grid>
          </Grid>
          <Modal 
            open={modalOpen}
            onClose={toggleModal}
            className={classes.paymentModal}
          >
            <Paper className={classes.paymentPaper}>
              {/* payment process */}
              <Elements stripe={stripePromise}>
                <CheckoutForm toggleModal={toggleModal} toggleSuccessModal={toggleSuccessModal} />
              </Elements>
            </Paper>
          </Modal>
          <Modal
            open={successModal}
            onClose={toggleSuccessModal}
            className={classes.successModal}
          >
            <Paper className={classes.successPaper}>
              <Image
                src={successIcon}
              />
              <Typography className={classes.mainText}>Top Up Done!</Typography>
              <Typography style={{margin:'5% 0'}}>{store.userReducer.paymentMsg}</Typography>
              <Button className={classes.button} href={'/userDetails'}>
                Ok
              </Button>              
           </Paper>
          </Modal>
        </Grid>
        <Footer />
      </Grid>
  );
};

export default Main;