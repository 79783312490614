import axios from "axios";
import { PAYMENT_MSG } from "./Types";
var token;

export const saveStripeInfo = (infoFormData, paymentFormData, history, setBackdrop, toggleModal, toggleSuccessModal) => (
	dispatch,
) => {
  setBackdrop(true);
	console.log('saveStripeInfo', infoFormData);
	return axios
    .post('https://admin.moneycomeasia.com/api/payment/save-stripe-info/', infoFormData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
    .then((res) => {
			console.log(res);
			if (res.data.message === "Success") {
        // setBackdrop(false);
        // toggleModal();
				// alert(res.data.data.extra_msg);
        // history.push("/userDetails");
        dispatch(createPayment(paymentFormData, history, setBackdrop, toggleModal, toggleSuccessModal));
			}
		})
    .catch((err) => {
      console.log(err);
    });
};

export const createPayment = (paymentFormData, history, setBackdrop, toggleModal, toggleSuccessModal) => (
	dispatch,
) => {
  token = localStorage.getItem('accessToken');
	console.log('createPayment', paymentFormData);
  console.log('token', token);
	return axios
    .post('https://admin.moneycomeasia.com/api/payment/createPayment/', paymentFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			},
    })
    .then((res) => {
			console.log(res);
      if (res.data.message === 'Success') {
        setBackdrop(false);
        toggleModal();
        // alert(res.data.data.extra_msg); 
        toggleSuccessModal();
        dispatch({type: PAYMENT_MSG, payload: res.data.data.extra_msg});
           
      }
		})
    .catch((err) => {
      console.log(err);
    });
};