import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import Dash from "../../assets/images/dashboard/dash.svg";
import creditRecord from "../../assets/images/dashboard/creditRecord.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import MerchantToolbar from "./MerchantToolbar";
import MerchantCards from "./MerchantCards";
import DashboardSpline from "./SplineChart";
import MerchantTable from "./MerchantTable";
import PaymentCard from "./PaymentCard";
import CreditHistoryTable from "./CreditHistoryTable";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbarJustifyBetween: {
    justifyContent: "center",
  },
  logo: {
    // width: '20%',
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      position: "relative",
    },
  },
  logoText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      // margin: '5%',
      textAlign: "left",
    },
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const drawer = (
    <div className={classes.root}>
      <Toolbar disableGutters={true} className={classes.toolbarJustifyBetween}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.logo}
        >
          <Typography className={classes.logoText}>MoneyCome</Typography>
        </IconButton>
      </Toolbar>
      <List>
        {["Dashboard", "Credit Record"].map((text, index) => (
          <ListItemButton
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemIcon>
              {index % 2 === 0 ? (
                <img src={Dash} alt="dashboard" />
              ) : (
                <MailIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
        }}
      >
        <MerchantToolbar
          handleDrawerToggle={() => handleDrawerToggle()}
          mobileOpen={mobileOpen}
        />

        {selectedIndex === 0 && (
          <Grid container>
            <Grid container>
              <Grid xs={12} md={8}>
                <MerchantCards />
              </Grid>
              <Grid xs={12} md={4}>
                <PaymentCard />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} md={8}>
                <DashboardSpline />
              </Grid>
            </Grid>
            <Grid container>
              <MerchantTable />
            </Grid>
          </Grid>
        )}
        {selectedIndex === 1 && <CreditHistoryTable />}

        {/* <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography> */}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
