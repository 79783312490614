import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import SignInPage from "../pages/SignIn";
import UserDetailsPage from "../pages/UserDetailsPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import MerchantDashboard from "../pages/MerchantDashboard";
import Products from "../pages/ProductPage";
import ContactUs from "../pages/ContactUs";
import TermsConditions from "../pages/TermsConditions";

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/signin">
          <SignInPage />
        </Route>
        <Route exact path="/userDetails">
          <UserDetailsPage />
        </Route>
        <Route exact path="/dashboard">
          <MerchantDashboard />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/contactus">
          <ContactUs />
        </Route>
        <Route exact path="/privacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/termsConditions">
          <TermsConditions />
        </Route>
      </Switch>
    </Router>
  );
};

export default MainRouter;
