import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantPointHistory } from "../../actions/dashboard";
import positive from "../../assets/images/dashboard/positive.svg";
import negative from "../../assets/images/dashboard/negative.svg";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.defaultBlack.main,
    fontSize: "18px",
    marginTop: "2%",
  },
  merchantTable: {
    marginTop: "20px",
    width: "100%",
  },
  creditHeader: {
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
  },
}));

export default function CreditHistoryTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getMerchantPointHistory());
  }, [dispatch]);
  let { pointsHistory } = useSelector((state) => state.dashboardReducer);

  const getMessage = (data) => {
    if (data) {
      if (data.points_credited_from_user_by_create_groupon > 0) {
        return {
          message: "Points credited from user by create groupon",
          amount: data.points_credited_from_user_by_create_groupon,
          type: "debit",
        };
      } else if (data.points_credited_from_user_by_create_flyer > 0) {
        return {
          message: "Points credited from user by create flyer",
          amount: data.points_credited_from_user_by_create_flyer,
          type: "debit",
        };
      } else if (data.points_credited_from_user_by_coupon_claim > 0) {
        return {
          message: "Points credited from user by coupon claim",
          amount: data.points_credited_from_user_by_coupon_claim,
          type: "debit",
        };
      } else if (data.points_credited_from_user_by_coupon_view > 0) {
        return {
          message: "Points credited from user by coupon view",
          amount: data.points_credited_from_user_by_coupon_view,
          type: "debit",
        };
      } else if (data.points_credited_from_user_by_coupon_share > 0) {
        return {
          message: "Points credited from user by coupon share",
          amount: data.points_credited_from_user_by_coupon_share,
          type: "debit",
        };
      } else if (data.points_credited_from_user_by_merchant_talk > 0) {
        console.log("entered");
        return {
          message: "Points credited from user by merchant talk",
          amount: data.points_credited_from_user_by_merchant_talk,
          type: "debit",
        };
      } else if (data.points_credited_from_Moneycome > 0) {
        return {
          message: "Points credited from moneycome",
          amount: data.points_credited_from_Moneycome,
          type: "credit",
        };
      } else {
        return { message: "", amount: "", type: "" };
      }
    }
  };
  const formatDate = (date) => {
    let formattedDate = moment(date).format("DD MMM, YYYY");
    if (formattedDate === "Invalid date") {
      return "NA";
    } else {
      return formattedDate;
    }
  };

  const getAmountStyle = (type) => {
    let color = type === "credit" ? { color: "#36D462" } : { color: "#FF5E50" };
    return color;
  };

  return (
    <div className={classes.merchantTable}>
      <Typography gutterBottom className={classes.mainText}>
        Credit Point Record
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead className={classes.creditHeader}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Credit/Debit
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                HKD Points
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Available Balance
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pointsHistory &&
              pointsHistory.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  {getMessage(row).message !== "" && (
                    <>
                      <TableCell component="th" scope="row">
                        {getMessage(row).message}
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell align="left">
                        <span style={getAmountStyle(getMessage(row).type)}>
                          <img
                            src={
                              getMessage(row).type === "credit"
                                ? positive
                                : negative
                            }
                            alt="arrow"
                          />
                          {getMessage(row).amount}
                        </span>
                      </TableCell>
                      <TableCell align="left">NA</TableCell>
                      <TableCell align="left">NA</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
