import React from "react";
import Products from "../components/Products";
import LandingPageAppBar from "../components/LandingPageAppBar";
import ProductSteps from "../components/ProductSteps";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { Grid } from "@material-ui/core";

const MerchantDashboard = () => {
  return (
    <Grid container>
      <LandingPageAppBar />
      <Products />
      <ProductSteps />
      <Newsletter />
      <Footer />
    </Grid>
  );
};

export default MerchantDashboard;
