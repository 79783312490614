import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import SignInForm from "../forms/signInForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    // width: '100%',
    // height: 'width',
  },
  paper: {
    padding: "5%",
  },
  headText: {
    fontWeight: 600,
    fontSize: "300%",
    color: "#B55FA5",
    margin: "5%",
    textAlign: "center",
  },
}));

export default function SimplePaper() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Typography className={classes.headText}>Sign In</Typography>
        <SignInForm />
      </Paper>
    </div>
  );
}
