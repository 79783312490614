import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import vector1 from "../../assets/images/dashboard/vector1.svg";
import vector2 from "../../assets/images/dashboard/vector2.svg";
import vector3 from "../../assets/images/dashboard/vector3.svg";
import vector4 from "../../assets/images/dashboard/vector4.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookMarkedValue,
  getServiceProvided,
  getClaimedCoupon,
} from "../../actions/dashboard";

const useStyles = makeStyles((theme) => ({
  merchantCards: {
    display: "flex",
    flexDirection: "column",

    padding: "10px 20px",
    borderRadius: "20px",
    minWidth: "calc(100% *(1/4) - 30px)",

    margin: "10px",
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.defaultBlack.main,
    fontSize: "18px",
    marginTop: "10px",
  },
  merchantCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const MerchantCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { bookmark, claimedCoupon, serviceProvided, nearbyUser } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    dispatch(getBookMarkedValue());
    dispatch(getServiceProvided());
    dispatch(getClaimedCoupon());
  }, [dispatch]);
  const merchantCardData = [
    { title: "Cash Value", value: "HKD 100", image: vector1, color: "#FFF9EB" },
    {
      title: "Service Provided",
      value: serviceProvided?.services_provided_count,
      image: vector2,
      color: "#F8EFF6",
    },
    {
      title: "Claimed Coupon",
      value: claimedCoupon?.total_coupon_claimed_count,
      image: vector3,
      color: "#EFFBF3",
    },
    {
      title: "Bookmarked",
      value: bookmark?.total_bookmarked_count,
      image: vector4,
      color: "#FFEDEF",
    },
  ];

  return (
    <div>
      <Typography gutterBottom className={classes.mainText}>
        Merchant Dashboard
      </Typography>
      <div className={classes.merchantCardContainer}>
        {merchantCardData.map((data) => {
          return (
            <div
              className={classes.merchantCards}
              style={{ background: `${data.color}` }}
            >
              <div>{data.title}</div>
              <div>{data.value}</div>
              <div style={{ alignSelf: "end" }}>
                <img src={data.image} alt="Vector" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MerchantCards;
