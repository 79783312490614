import React from 'react';
import {Grid, Typography, Button, Hidden, Grow, Paper} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: 'hidden',
    // display: 'flex',
    opacity: 0.3,
    "&:hover":{
      opacity: 1,
    }
  },
  avatar:{
    width: 'auto',
    height: 'auto',
    margin: '5%',
    // opacity: 0.3,
    // "&:hover":{
    //   opacity: 1,
    // }
  },
  contentPaper:{
    padding:'10%',
    textAlign: 'center',
    width: '150%',
    height: '50%',
    zIndex: 5,
  },
  nameText:{
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "200%",
    color: theme.palette.secondary.main,
    lineHeight: '120%',
  },
  titleText:{
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '120%',
    lineHeight: '27px',
    color: theme.palette.defaultBlack.main,
  },
  paraText:{
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.defaultBlack.main,
    margin: '5%',
  },
}));


export default function TestimonialImageOverlay(props) {
  const classes = useStyles();
  const [showContent, setShowContent] = React.useState(false);

  return (
    <div className={classes.root} onMouseEnter={() => setShowContent(true)} onMouseLeave={() => setShowContent(false)}>
      <Avatar src={props.image} className={classes.avatar} />
        <Grow in={showContent}>
          <Paper className={classes.contentPaper}>
            <Typography className={classes.nameText}>{props.name}</Typography>
            <Typography className={classes.titleText}>{props.title}</Typography>
            <Typography className={classes.paraText}>{props.testimonial}</Typography>
          </Paper>
        </Grow>
    </div>
  );
}