import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  TextField,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "20%",
      // minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
    justifyContent: "center",
    // minHeight: "70vh",
    padding: "5%",
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
    textAlign: "center",
  },
  FormSection: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
      // width: '100%'
    },
  },
  ImageSection: {
    margin: "0 5%",
  },
  DashImage: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
    //margin: "auto",
    //position: "absolute",
  },
  cardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.secondary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  cardText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    color: theme.palette.defaultBlack.main,
    lineHeight: "150%",
    textAlign: "center",
    margin: 10,
  },
  emailButton: {
    marginLeft: "30%",
    width: "150px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.defaultBlack.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.defaultWhite.main,
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginTop: "20%",
    },
  },
  emailField: {
    width: "300px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.defaultWhite.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  newsletterText: {
    padding: "5%",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column-reverse",
      // width: "100%",
      // position: "relative",
      // justifyContent: 'center',
      alignItems: "center",
      textAlign: "center",
      // marginBottom: 0
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid>
      <Grid className={classes.root}>
        <Typography gutterBottom className={classes.mainText}>
          FAQs
        </Typography>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Collapsible Group Item #1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Collapsible Group Item #2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Collapsible Group Item #3</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
