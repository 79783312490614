import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getNearbyUser } from "../../actions/dashboard";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.defaultBlack.main,
    fontSize: "18px",
    marginTop: "2%",
  },
  merchantTable: {
    marginTop: "20px",
    width: "100%",
  },
  viewAllLink: {
    background: " none!important",
    border: "none",
    padding: "0 !important",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "12px",
    color: "#B55FA5",
    textDecoration: "underline",
    cursor: "pointer",
  },
  merchantTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  status: {
    padding: "10px",
    borderRadius: "20px",
  },
}));

export default function MerchantTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAll, setShowAll] = React.useState(false);
  React.useEffect(() => {
    dispatch(getNearbyUser());
  }, [dispatch]);
  let { nearbyUser } = useSelector((state) => state.dashboardReducer);
  let userData = nearbyUser?.nearby_user_requests;
  const spanStyle = (status) => {
    if (status === "Approved") {
      return { background: "#E9F9EE", color: "#36D462" };
    } else if (status === "Rejected") {
      return { background: "#FEECEE", color: "#FF5E50" };
    } else if (status === "Pending") {
      return { background: "#FBEDF8", color: "#B55FA5" };
    }
  };

  const formatDate = (date, format) => {
    let formattedDate = moment(date, format).format("DD MMM, YYYY");
    if (formattedDate === "Invalid date") {
      return "NA";
    } else {
      return formattedDate;
    }
  };

  return (
    <div className={classes.merchantTable}>
      <div className={classes.merchantTitleContainer}>
        <Typography gutterBottom className={classes.mainText}>
          Request Overview ({userData && userData.length})
        </Typography>
        <Grid item>
          <button
            className={classes.viewAllLink}
            onClick={() => setShowAll((prevState) => !prevState)}
          >
            {showAll ? "View Less" : "View All"}
          </button>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Requests
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Due Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="center"
              >
                People Joined
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Location
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, fontFamily: "Poppins" }}
                align="left"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData &&
              !showAll &&
              userData.slice(0, 4).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{ width: 28, height: 28 }}
                        alt={row.user.first_name}
                        src={row.user.profile_pic}
                      />
                      &nbsp; {row.user.first_name + " " + row.user.last_name}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    {row.subcategory.subcategory}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(row.expiredAt, "YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center">{row.number_of_people}</TableCell>
                  <TableCell align="left">{row.user.location}</TableCell>
                  <TableCell align="left">
                    <span
                      className={classes.status}
                      style={spanStyle(row.status)}
                    >
                      {row.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            {userData &&
              showAll &&
              userData.map((row) => (
                <TableRow
                  key={row.user.first_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.user.first_name + " " + row.user.last_name}
                  </TableCell>
                  <TableCell align="left">
                    {row.subcategory.subcategory}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(row.expiredAt, "YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center">{row.number_of_people}</TableCell>
                  <TableCell align="left">{row.user.location}</TableCell>
                  <TableCell align="left">
                    <span
                      className={classes.status}
                      style={spanStyle(row.status)}
                    >
                      {row.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
