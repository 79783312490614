import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Card,
  CardContent,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import groupon from "../assets/images/products/groupon.svg";
import applemusic from "../assets/images/products/apple_music.svg";
import merchantrequest from "../assets/images/products/merchant_request.svg";
import dotsLeft from "../assets/images/products/dots_left.png";
import mainBg from "../assets/images/main-bg.svg";
import moneyHandIcon from "../assets/images/money-hand-icon.svg";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column",
      //   paddingTop: "20%",
      //   // height:'100%',
      //   minHeight: "80vh",
      //   alignItems: "center",
      //   textAlign: "center",
      //   width: "100%",
    },
    justifyContent: "center",
    // minHeight: "100vh",
  },
  firstSection: {
    [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column",
      //   paddingTop: "20%",
      //   // height:'100%',
      //   minHeight: "80vh",
      //   alignItems: "center",
      //   textAlign: "center",
      //   width: "100%",
    },
    justifyContent: "center",
    // minHeight: "100vh",
    backgroundSize: "cover",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${mainBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    padding: "20px",
  },
  secondSection: {
    padding: "20px",
  },
  thirdSection: {
    padding: "20px",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    marginTop: "6%",
  },
  featuresBox: {
    display: "flex",
    flexDirection: "row",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // alignItems: "center",
      // textAlign: "center",
      justifyContent: "center",
      padding: "10%",
    },
  },
  chatScreenTop: {
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
    },
  },
  iconContainer: {
    margin: "5%",
  },
  productCards: {
    borderTopRightRadius: "40px",
    maxWidth: "500px",
  },
  productCardsSecond: {
    borderTopLeftRadius: "40px",
    paddingRight: "40px",
    maxWidth: "500px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },
  },
  secondProduct: {
    paddingRight: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      order: 2,
    },
  },
  separator: {
    width: "100%",
    maxWidth: "70%",
    borderBottom: "1px solid black",
  },
  productCardsHeading: {
    paddingBottom: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  productCardsDescription: {
    paddingTop: "20px",
    fontFamily: theme.typography.fontFamily,
  },
  banner: {
    width: "100%",
    padding: "30px 30px",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    margin: "50px 0px 0px 0px",
  },
  innovativeText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    padding: "50px 0px",
  },
  dots: {
    position: "absolute",
    right: "150px",
    height: "100px",
  },
}));

const Products = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.sectionContainer}>
        <Typography gutterBottom className={classes.mainText}>
          Products
        </Typography>
        <div className={classes.banner}>
          The first platform to change depending on actual result. Start a new
          era of marketing.
        </div>
        <Typography
          gutterBottom
          className={classes.innovativeText}
          variant="h6"
        >
          3 innovative promotion strategy
        </Typography>
        <Grid
          container
          className={classes.firstSection}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Image
              src={groupon}
              height={500}
              className={classes.chatScreenTop}
            />
          </Grid>
          <Grid item>
            <Card className={classes.productCards} sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography className={classes.productCardsHeading}>
                  Group-buy Advertisement
                </Typography>
                <div className={classes.separator}></div>
                <Typography className={classes.productCardsDescription}>
                  Groupon target housing estate or any building, aim at their
                  specific needs.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item>
            <Image className={classes.dots} src={dotsLeft} alt="dots" />
          </Grid> */}
        </Grid>
        <Grid
          container
          className={classes.secondSection}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.secondProduct}>
            <Card className={classes.productCardsSecond} sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography className={classes.productCardsHeading}>
                  Realtime Advertisement
                </Typography>
                <div className={classes.separator}></div>
                <Typography className={classes.productCardsDescription}>
                  Merchants publish offers at real-time. (specific time,
                  specific people, Precise location).
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Image
              src={applemusic}
              height={500}
              className={classes.chatScreenTop}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.thirdSection}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Image
              src={merchantrequest}
              height={500}
              className={classes.chatScreenTop}
            />
          </Grid>
          <Grid item>
            <Card className={classes.productCards} sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography className={classes.productCardsHeading}>
                  Merchant Request
                </Typography>
                <div className={classes.separator}></div>
                <Typography className={classes.productCardsDescription}>
                  Users can create or join nearby request, and suitable merchant
                  will receive these group orders.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item>
            <Image className={classes.dots} src={dotsLeft} alt="dots" />
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Products;
