import {
  GET_BOOKMARK_VALUE,
  GET_CLAIMED_COUPON,
  GET_SERVICE_PROVIDED,
  GET_NEARBY_USER,
  GET_POINTS_HISTORY,
  GET_MERCHANT_POINTS,
} from "../actions/Types";

const initialState = {
  bookmark: "",
  claimedCoupon: "",
  serviceProvided: "",
  nearbyUser: "",
  pointsHistory: "",
  merchantPoints: "",
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKMARK_VALUE:
      return { ...state, bookmark: action.payload };
    case GET_CLAIMED_COUPON:
      return { ...state, claimedCoupon: action.payload };
    case GET_SERVICE_PROVIDED:
      return { ...state, serviceProvided: action.payload };
    case GET_NEARBY_USER:
      return { ...state, nearbyUser: action.payload };
    case GET_POINTS_HISTORY:
      return { ...state, pointsHistory: action.payload };
    case GET_MERCHANT_POINTS:
      return { ...state, merchantPoints: action.payload };
    default:
      return state;
  }
}
