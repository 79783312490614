import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
// import ComingSoon from '../components/ComingSoon';
import Main from "../components/Main";
import WhyUs from "../components/WhyUs";
import PromotionArea from "../components/PromotionArea";
import Working from "../components/Working";
// import JoinMembership from '../components/JoinMembership';
import Footer from "../components/Footer";
import OurFeatures from "../components/OurFeatures";
import Faqs from "../components/Faqs";
import Testimonials from "../components/Testimonials";
import Newsletter from "../components/Newsletter";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    margin: 0,
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      {/* <ComingSoon /> */}
      <Main />
      <WhyUs />
      <OurFeatures />
      {/* <Working />
      <PromotionArea />
      <Testimonials />
      <Faqs />
      <Newsletter /> */}
      <Footer />
    </Grid>
  );
};

// hided Working, PromotionArea, Testimonials, Faqs, Newsletter,

export default LandingPage;
