import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  TextField,
  Backdrop,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {saveStripeInfo} from '../actions/payment';
import { useHistory } from "react-router-dom";
import CustomCardInput from '../components/reusableComponents/CustomCardInput';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: 12,
    padding: 8,
  },
  fieldLabel: {
    color: "#272343",
    paddingTop: 2,
    paddingBottom: 2,
  },
  inputField: {
    // padding: 10,
  },
  submitButton: {
    backgroundColor: "#B55FA5",
    color: "#fff",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      background: "#B55FA5",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  
}));


const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();  
  const dispatch = useDispatch();
  const history = useHistory();


// Handle real-time validation errors from the CardElement.
const handleChange = (event) => {
  if (event.error) {
    setError(event.error.message);
  } else {
    setError(null);
  }
};

// Handle form submission.
const handleSubmit = async (event) => {
  console.log(event.preventDefault());
  event.preventDefault();
	const card = elements.getElement(CardElement);
	const {paymentMethod, error} = await stripe.createPaymentMethod({
		type: 'card',
    card: card
  });
  // console.log('payment_method_id', paymentMethod.id);
  var email = localStorage.getItem('userMail');
  var infoFormData = new FormData();
  infoFormData.append('payment_method_id', paymentMethod.id);
  infoFormData.append('email', email);

  var paymentFormData = new FormData();
  paymentFormData.append('payment_method_id', paymentMethod.id);
  paymentFormData.append('email', email)
  paymentFormData.append('amount', amount*100);
  dispatch(saveStripeInfo(infoFormData, paymentFormData, history, setBackdrop, props.toggleModal, props.toggleSuccessModal));
	console.log('error', error);
};

const CARD_OPTIONS = {
  iconStyle: "default",
  style: {
    base: {
      color: "#000",
      fontSize: "16px",
      fontSmoothing: "antialiased",
    }
  }
};

return (
  <Grid>
    <Typography className={classes.fieldLabel}>Card Details</Typography>
    <CardElement id="card-element" onChange={handleChange} options={CARD_OPTIONS} inputField={CustomCardInput} />
    <div className="card-errors" role="alert">{error}</div>
    <Grid style={{margin: 30}}>
      
    <Typography className={classes.fieldLabel}>Enter Here Amount to Top-up Wallet</Typography>
      {/* <Typography className={classes.fieldLabel}>Amount</Typography> */}
    <TextField
      name="amount"
      value={amount}
      onChange={(event) => setAmount(event.target.value)}
      fullWidth
      required={true}
      variant="standard"
      placeholder="Amount"
      inputProps={{ className: classes.inputField }}
      // color="secondary"
    />
    </Grid>
    <Grid style={{margin: 20}}>
    { <Typography className={classes.fieldLabel}>Credit Points</Typography> }
    <TextField
      disabled
      name="amount"
      value={amount*1000}
      onChange={(event) => setAmount(event.target.value)}
      fullWidth
      // required={true}
      variant="standard"
      label="Credit Points"
      color="secondary"
      inputProps={{ className: classes.inputField }}
    />
    </Grid>
    <Button
      variant="contained"
      onClick={handleSubmit}
      fullWidth
      className={classes.submitButton}
    >
      Submit Payment
    </Button>
    <Backdrop className={classes.backdrop} open={backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </Grid>
 );
};
export default CheckoutForm;