import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Toolbar,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import mainImg from "../assets/images/main-img.svg";
import mainBg from "../assets/images/main-bg.svg";
import WhyUs from "../components/WhyUs";
import { Carousel } from "react-responsive-carousel";
import dots from "../assets/images/products/dots_left.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  sectionContainer: {
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "5%",
      // height:'100%',
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },

    // minHeight: "100vh",
    padding: "0% 5% 10% 3%",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${mainBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
  },
  sectionContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // paddingTop: "20%",
      // height:'100%',
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "flex-start",
      width: "100%",
    },
    justifyContent: "center",
    // minHeight: "100vh",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    paddingLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "0 5% 5% 0",
      // paddingTop: 0,
      // paddingLeft:0,
      // width: '100%'
    },
  },
  ImageSection: {
    display: "flex",
    alignItems: "flex-start",
    // margin: '5%',
    //padding: '5%',
    [theme.breakpoints.down("sm")]: {
      //marginTop: 10,
      // order: 2,
    },
    //marginTop: "60px",
  },
  DashImage: {
    // height: "100%",
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
      overflow: "hidden",
    },
    //margin: "auto",
    //position: "absolute",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
  },
  videoStyles: {
    width: "100%",
    // height: '80%',
  },
  sectionCarousel: {
    padding: "3% 1% 0% 1%",
    background: "#B55FA50A",
    margin: "5% 0% 8% 0%",
    [theme.breakpoints.down("sm")]: {
      margin: "2% 0%",
    },
  },
  dots: {
    position: "absolute",
    left: "30px",
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <Grid>
      <LandingPageAppBar />
      <Grid className={classes.sectionCarousel} container>
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          showStatus={false}
          className="talksCarouselComp"
        >
          <Grid>
            Advertising expense never guarantee anything, can be 100% wasted.
          </Grid>
          <Grid>
            Advertising expense never guarantee anything, can be 100% wasted.
          </Grid>
          <Grid>
            Advertising expense never guarantee anything, can be 100% wasted.
          </Grid>
        </Carousel>
        <Image className={classes.dots} src={dots} height="80" />
      </Grid>
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid className={classes.sectionContent} container>
            <Grid item xs={12} md={6} className={classes.FormSection}>
              <Grid>
                <Typography gutterBottom className={classes.mainText}>
                  Best Merchant <br /> Services
                </Typography>
              </Grid>
              <Hidden only="xs">
                <Grid>
                  <Typography className={classes.captionText}>
                  MoneyCome is an E-platform which community can group their needs (service or product, luxury or daily commodity), and we match these orders with suitable merchants. And merchants can publish team-buy offers at a specific time, precise location to specific users which can provide better price. Users can also create their need to find suitable supplier.

                  </Typography>
                </Grid>
              </Hidden>
              <Grid className={classes.requestDemoMarginBottom}>
                {/* <TypeWriter messages={msgs} /> */}
              </Grid>
              <Grid>
                <a
                  href="https://apps.apple.com/hk/app/moneycome-new-shop-earn-era/id1583926321?l=en"
                  className={classes.anchor}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Download Now
                  </Button>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              {/* <video src={video} controls="controls" loop='loop' autoplay="autoplay" className={classes.videoStyles}/> */}
              {/* <iframe width="100%" height="80%" src="https://www.youtube.com/embed/668nUCeBHyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <Image
                // fluid
                src={mainImg}
                className={classes.DashImage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
