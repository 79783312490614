import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import promotionImg from "../assets/images/promotion-img.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      clipPath: "polygon(0 0, 100% 10%, 100% 100%, 0 90%)",
      paddingTop: "30%",
      minHeight: "100vh",
      alignItems: "center",
      textAlign: "center",
      // width: '100%'
    },
    justifyContent: "center",
    minHeight: "80vh",
    padding: "10%",
    backgroundColor: theme.palette.secondary.main,
    clipPath: "polygon(0 0, 100% 20%, 100% 100%, 0 80%)",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // paddingLeft:'5%',
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
      // width: '100%'
    },
  },
  ImageSection: {
    display: "flex",
    justifyContent: "right",
    margin: "10% 0 0 20%",
    //padding: '5%',
    [theme.breakpoints.down("sm")]: {
      //marginTop: 10,
      // order: 2,
    },
    //marginTop: "60px",
  },
  DashImage: {
    overflow: "hidden",

    // height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",

      width: "100%",
      position: "relative",
    },
    //margin: "auto",
    //position: "absolute",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultWhite.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "150px",
    color: theme.palette.defaultBlack.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.defaultWhite.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.defaultWhite.main,
    lineHeight: "120%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      overflow: "hidden",
    },
  },
}));

const PromotionArea = () => {
  const classes = useStyles();

  return (
    <Grid>
      <LandingPageAppBar />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                This is not just an app, It’s Business Solution For You
              </Typography>
            </Grid>
            <Hidden only="xs">
              <Grid>
                <Typography className={classes.captionText}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Scelerisque diam potenti adipiscing elit.
                </Typography>
              </Grid>
            </Hidden>
            <Grid className={classes.requestDemoMarginBottom}>
              {/* <TypeWriter messages={msgs} /> */}
            </Grid>
            <Grid>
              <a
                // href={"#virucontact"}
                className={classes.anchor}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Get the App
                </Button>
              </a>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.ImageSection}>
            {/* <video src={video} controls="controls" loop='loop' autoplay="autoplay" className={classes.videoStyles}/> */}
            {/* <iframe width="100%" height="80%" src="https://www.youtube.com/embed/668nUCeBHyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <Image
              // fluid
              src={promotionImg}
              className={classes.DashImage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromotionArea;
