import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import whoWeAreImg from "../assets/images/who-we-are-img.svg";
import moneyHandIcon from "../assets/images/money-hand-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "20%",
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
    justifyContent: "center",
    textAlign: "center",
    minHeight: "100vh",
    padding: "10%",
    backgroundColor: theme.palette.primary.main,
    // backgroundImage: `url(${mainBg})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'top'
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
  },
  featuresBox: {
    display: "flex",
    flexDirection: "row",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // alignItems: "center",
      // textAlign: "center",
      justifyContent: "center",
      padding: "10%",
    },
  },
  chatScreenTop: {
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
    },
  },

  iconContainer: {
    margin: "5%",
  },
}));

const WhyUs = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} id="whoWeAre">
      <Grid container className={classes.sectionContainer}>
        <Typography gutterBottom className={classes.mainText}>
          Who We Are
        </Typography>
        <Typography>
        MoneyCome is a mutual platform connecting merchants and users, point-to-cash reward programme 

        </Typography>
        <Image src={whoWeAreImg} className={classes.chatScreenTop} />
        {/* <Grid container>
          <Grid className={classes.featuresBox} xs={12} md={6}>
            <Grid className={classes.iconContainer} item>
              <Image src={moneyHandIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                Lorem ipsum dolor sit
              </Typography>
              <Typography className={classes.captionText}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.featuresBox} xs={12} md={6}>
            <Grid className={classes.iconContainer} item>
              <Image src={moneyHandIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                Lorem ipsum dolor sit
              </Typography>
              <Typography className={classes.captionText}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.featuresBox} xs={12} md={6}>
            <Grid className={classes.iconContainer} item>
              <Image src={moneyHandIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                Lorem ipsum dolor sit
              </Typography>
              <Typography className={classes.captionText}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.featuresBox} xs={12} md={6}>
            <Grid className={classes.iconContainer} item>
              <Image src={moneyHandIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                Lorem ipsum dolor sit
              </Typography>
              <Typography className={classes.captionText}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default WhyUs;
