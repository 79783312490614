import React from "react";
import "./App.css";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import MainRouter from "./routers/MainRouter";
import { CssBaseline } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./store";

export let theme = createTheme({
  palette: {
    primary: {
      main: "#FFF", //white
    },
    secondary: {
      main: "#B55FA5", //purple
    },
    footerWhite: {
      main: "#F6F6F6", //footer white
    },
    defaultWhite: {
      main: "#FFF",
    },
    defaultBlack: {
      main: "#434343",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainRouter />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
