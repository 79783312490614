import React from "react";
import Contact from "../components/Contact";
import LandingPageAppBar from "../components/LandingPageAppBar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import { Grid } from "@material-ui/core";

const ContactUs = () => {
  return (
    <Grid container>
      <LandingPageAppBar />
      <Contact />
      <Newsletter />
      <Footer />
    </Grid>
  );
};

export default ContactUs;
