import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  LinearProgress,
  CircularProgress,
  // TextField
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { emailSignin } from "../actions/user";
import * as yup from "yup";


const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: 12,
    padding: 8,
  },
  fieldLabel: {
    color: "#272343",
    paddingTop: 2,
    paddingBottom: 2,
  },
  inputField: {
    padding: 10,
    borderColor: 'black'
  },
  submitButton: {
    backgroundColor: "#B55FA5",
    color: "#fff",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      background: "#B55FA5",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    // .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const SignInForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (data, actions) => {
    localStorage.setItem('userMail', data.email);
    console.log('user', data);
    var bodyFormData = new FormData();
    bodyFormData.append('email', data.email);
    bodyFormData.append('password', data.password);
    dispatch(emailSignin(bodyFormData, actions, history));
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, setFieldValue, values }) => (
        <Form className={classes.formContainer}>
          <Typography className={classes.fieldLabel}>Email</Typography>
          <Field
            component={TextField}
            name="email"
            value={values.email}
            fullWidth
            type="email"
            variant="outlined"
            color="secondary"
            inputProps={{ className: classes.inputField }}
          />
          <Typography className={classes.fieldLabel}>Password</Typography>
          <Field
            component={TextField}
            name="password"
            value={values.password}
            fullWidth
            type="password"
            variant="outlined"
            color="secondary"
            inputProps={{ className: classes.inputField }}
          />
          <Button
            variant="contained"
            onClick={submitForm}
            fullWidth
            className={classes.submitButton}
          >
            Log In
          </Button>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;