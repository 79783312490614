import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import CardMedia from "@mui/material/CardMedia";
import Logo from "../assets/images/logo.svg";
import contactDots from "../assets/images/contactDots.svg";
import { Image } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  productCards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderTopRightRadius: "40px",
    maxWidth: "700px",
    marginTop: "30px",
  },

  secondProduct: {
    paddingRight: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      order: 2,
    },
  },
  separator: {
    width: "100%",
    maxWidth: "70%",
    borderBottom: "1px solid black",
  },
  productCardsHeading: {
    paddingBottom: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  productCardsDescription: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.defaultBlack.main,
    padding: "10px",
  },
  contactCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px 200px 0px",
    padding: "0px 10px",
  },
  dots: {
    position: "absolute",
    left: "-50px",
    bottom: "-30px",
    height: "50px",
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "36px",
    color: theme.palette.secondary.main,
    margin: "20px 0px",
    position: "relative",
  },
  productCardsContent: {
    flex: 1,
    paddingRight: "20px",
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.contactCardContainer}>
      <Typography gutterBottom className={classes.mainText}>
        Contact Us
        <Image className={classes.dots} src={contactDots} alt="dots" />
      </Typography>
      <Card className={classes.productCards} sx={{ minWidth: 200 }}>
        <CardMedia
          component="img"
          sx={{ maxWidth: 150 }}
          image={Logo}
          alt="Logo"
        />
        <CardContent className={classes.productCardsContent}>
          <Typography className={classes.productCardsDescription}>
            <div>Email Address:</div>
            <div style={{ fontWeight: "400", paddingLeft: "20px" }}>
              info@in-bok.com
            </div>
          </Typography>
          <Typography className={classes.productCardsDescription}>
            <div>Contact Number:</div>
            <div style={{ fontWeight: "400", paddingLeft: "20px" }}>
              37428783
            </div>
          </Typography>
          <Typography className={classes.productCardsDescription}>
            <div>Office Hour:</div>
            <div style={{ fontWeight: "400", paddingLeft: "20px" }}>
              Monday to Saturday 9 am to 6 pm
            </div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Contact;
