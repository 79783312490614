import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  TextField,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import newsletterBg from "../assets/images/newsletter-bg.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // paddingTop: '20%',
      // minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      padding: "20% 0",
    },
    justifyContent: "center",
    // minHeight: "80vh",
    // marginTop:'30%',
    padding: "15% 0 10% 50%",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${newsletterBg})`,
    backgroundRepeat: "no-repeat",
    // backgroundSize: 'cover',
    backgroundPosition: "right",
  },
  cardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  cardText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
    margin: 10,
  },
  emailButton: {
    marginLeft: "30%",
    width: "150px",
    color: theme.palette.secondary.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginTop: "20%",
    },
  },
  emailField: {
    width: "100%",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    // borderRadius: "20px",
    // border: '1px solid',
    borderColor: theme.palette.defaultWhite.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  newsletterText: {
    padding: "5%",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column-reverse",
      // width: "100%",
      // position: "relative",
      // justifyContent: 'center',
      alignItems: "center",
      textAlign: "center",
      // marginBottom: 0
    },
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid className={classes.root}>
        <Grid container className={classes.sectionContainer}>
          <Grid>
            <Typography gutterBottom className={classes.cardTitle}>
              Newsletter
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom className={classes.cardText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. .
            </Typography>
          </Grid>
          <Grid container justify="center" className={classes.newsletterText}>
            <Grid item>
              <TextField
                id="filled-textare"
                label="Enter your email"
                variant="outlined"
                size="small"
                multiline
                className={classes.emailField}
              ></TextField>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.emailButton}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
            <Typography gutterBottom className={classes.cardText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. .
            </Typography>
          </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Main;
