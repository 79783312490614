import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Hidden,
  Typography,
  Drawer,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Image } from "semantic-ui-react";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "1%",
    paddingLeft: "5%",
    paddingRight: "5%",
    boxShadow: "none",
    background: "transparent",
  },
  button: {
    width: "150px",
    color: theme.palette.defaultWhite.main,
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    borderRadius: "20px",
    fontSize: 16,
  },
  appBarElevations: {
    boxShadow: "none",
  },
  toolbarJustifyBetween: {
    justifyContent: "space-between",
  },
  navLinksGrid: {
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  anchor: {
    color: theme.palette.defaultWhite.main,
    textDecoration: "none",
  },
  anchorText: {
    color: theme.palette.defaultBlack.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: "100%",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      // fontSize: '12px',
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: '16px',
      margin: "5%",
      textAlign: "left",
    },
  },
  logo: {
    // width: '20%',
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      position: "relative",
    },
  },
  drawerMenu: {
    backgroundColor: theme.palette.primary.main,
    // height: '50vh',
    alignItems: "center",
  },
  logoText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: "30px",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      // margin: '5%',
      textAlign: "left",
    },
  },
}));

const LandingPageAppBar = (props) => {
  const classes = useStyles();
  const [menuState, setMenuState] = React.useState(false);

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  return (
    <AppBar
      className={classes.root}
      position="static"
      // style={{boxShadow: 'none', background:'transparent'}}
    >
      <Toolbar disableGutters={true} className={classes.toolbarJustifyBetween}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.logo}
        >
          <Typography className={classes.logoText}>MoneyCome</Typography>
          {/* <Image src={logoRectangle} style={{width:'100%'}}/> */}
        </IconButton>
        <Hidden smDown>
          <Grid className={classes.navLinksGrid}>
            <a href={"/"} className={classes.anchor}>
              <Typography className={classes.anchorText}>Home</Typography>
            </a>
            <a href={"/#whoWeAre"} className={classes.anchor}>
              <Typography className={classes.anchorText}>Who We Are</Typography>
            </a>
            <a href={"/products"} className={classes.anchor}>
              <Typography className={classes.anchorText}>Products</Typography>
            </a>
            <a href={"/#features"} className={classes.anchor}>
              <Typography className={classes.anchorText}>
                Our Features
              </Typography>
            </a>
            <a href={"/contactus"} className={classes.anchor}>
              <Typography className={classes.anchorText}>Contact Us</Typography>
            </a>
            {/* <a href={"/signin"} className={classes.anchor}>
              <Typography className={classes.anchorText}>Log In</Typography>
              </a> */}
            {props.loggedIn ? (
              <RouterLink to="/signin" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Log Out
                </Button>
              </RouterLink>
            ) : (
              <RouterLink to="/signin" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Log In
                </Button>
              </RouterLink>
            )}
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <IconButton
            edge="end"
            color="secondary"
            aria-label="menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={menuState} onClose={toggleMenu}>
            <Grid className={classes.drawerMenu}>
              <a href={"/"} className={classes.anchor}>
                <Typography className={classes.anchorText} onClick={toggleMenu}>
                  Home
                </Typography>
              </a>
              <a href={"/#whoWeAre"} className={classes.anchor}>
                <Typography className={classes.anchorText} onClick={toggleMenu}>
                  Who We Are
                </Typography>
              </a>
              <a href={"/products"} className={classes.anchor}>
                <Typography className={classes.anchorText} onClick={toggleMenu}>
                  Products
                </Typography>
              </a>
              <a href={"/#features"} className={classes.anchor}>
                <Typography className={classes.anchorText} onClick={toggleMenu}>
                  Our Features
                </Typography>
              </a>
              <a href={"/contactus"} className={classes.anchor}>
                <Typography className={classes.anchorText} onClick={toggleMenu}>
                  Contact Us
                </Typography>
              </a>
              {/* <RouterLink to="/freetrial" style={{ textDecoration: "none" }}> */}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={toggleMenu}
                href={"/signin"}
              >
                Log In
              </Button>
              {/* </RouterLink> */}
            </Grid>
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default LandingPageAppBar;
