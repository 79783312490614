import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ArgumentScale, Animation } from "@devexpress/dx-react-chart";
import { curveCatmullRom, line } from "d3-shape";
import { scalePoint } from "d3-scale";
import CreditIcon from "../../assets/images/dashboard/credit.svg";
import DebitIcon from "../../assets/images/dashboard/debit.svg";
import SplineDropBox from "./SplineDropBox";
// import { energyConsumption as data } from "../../../demo-data/data-vizualization";
let data = [
  {
    month: "Jan",
    credit: 600.8,
    debit: 937.6,
  },
  {
    month: "Feb",
    credit: 74.2,
    debit: 308.6,
  },
  {
    month: "Mar",
    credit: 170,
    debit: 128.5,
  },
  {
    month: "April",
    credit: 200,
    debit: 241.5,
  },
  {
    month: "May",
    credit: 19,
    debit: 119.3,
  },
  {
    month: "June",
    credit: 6.1,
    debit: 123.6,
  },
];

const Line = (props) => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }) => arg)
      .y(({ val }) => val)
      .curve(curveCatmullRom)}
    color={props.color}
  />
);

const Text = withStyles((theme) => ({
  title: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: theme.palette.defaultBlack.main,
    fontSize: "14px",
  },
}))((props) => {
  const { text, classes } = props;
  const [mainText, subText] = text.split("\\n");
  return (
    <div style={{ alignSelf: "center" }}>
      <Typography className={classes.title}>{mainText}</Typography>
    </div>
  );
});

const legendStyles = (theme) => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
});
const legendLabelStyles = (theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "500 !important",
  },
  span: {
    fontSize: "12px !important",
  },
});
const legendItemStyles = () => ({
  item: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <>
    <Legend.Root {...restProps} className={classes.root} />
    <SplineDropBox />
  </>
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);
const Item = withStyles(legendItemStyles, { name: "LegendItem" })(
  legendItemBase
);
const demoStyles = () => ({
  chart: {
    paddingRight: "30px",
    height: "400px !important",
  },
});
const SpecialMarkerComponent = ({ name, color }) => {
  const Icon = name === "Credited" ? CreditIcon : DebitIcon;
  // xlinkHref is use for safari
  return (
    <>
      <img src={Icon} alt="Icon" />
    </>
  );
};

class DashboardSpline extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data,
    };
  }

  render() {
    const { data: chartData } = this.state;
    const { classes } = this.props;

    return (
      <Paper>
        <Chart data={chartData} className={classes.chart}>
          <ArgumentScale factory={scalePoint} />
          <ArgumentAxis />
          <ValueAxis />

          <LineSeries
            name="Credited"
            valueField="credit"
            argumentField="month"
            seriesComponent={Line}
            color="green"
          />
          <LineSeries
            name="Debited"
            valueField="debit"
            argumentField="month"
            seriesComponent={Line}
            color="red"
          />

          <Title text="Transacion Overview" textComponent={Text} />
          <Legend
            position="top"
            rootComponent={Root}
            itemComponent={Item}
            labelComponent={Label}
            markerComponent={SpecialMarkerComponent}
          />

          <Animation />
        </Chart>
      </Paper>
    );
  }
}

export default withStyles(demoStyles, { name: "DashboardSpline" })(
  DashboardSpline
);
