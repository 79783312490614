import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles, Paper, Modal, Typography } from "@material-ui/core/";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import CheckoutForm from "../../forms/checkoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import successIcon from "../../assets/images/success-icon.png";
import { Image } from "semantic-ui-react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getUserProfile } from "../../actions/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const stripePromise = loadStripe(
  "pk_live_51Mc1GrK32JxNbv5RGXc5vsIaJpbsq17jo7OVRS4CeEVUzzWVH3DDI8QdJbVtEkuoBQNoxD0wjaTVjabUWoqklV4i00ftCE8DlT "
);

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
  },
  topUpButton: {
    textTransform: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    background: "#fff",
    padding: "5px 20px",
    borderRadius: "10px",
    marginRight: "10px",
  },
  paymentModal: {
    width: "50%",
    margin: "auto",
    marginTop: "10%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  successModal: {
    width: "50%",
    margin: "auto",
    marginTop: "10%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  successPaper: {
    padding: "5% 20%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5%",
    },
  },
  paymentPaper: {
    padding: "5% 20%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5%",
    },
  },
}));

export default function MerchantToolbar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [logoutAnchorEl, setLogoutAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLogoutMenu = Boolean(logoutAnchorEl);
  const store = useSelector((state) => state);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogoutMenuClose = () => {
    setLogoutAnchorEl(null);
  };
  const handleLogoutMenuOpen = (event) => {
    setLogoutAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    history.push("/");
  };
  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button
          className={classes.topUpButton}
          size="small"
          startIcon={
            <IconButton
              sx={{
                backgroundColor: "#B55FA5",
                color: "#fff",
                width: 24,
                height: 24,
              }}
            >
              <ArrowUpwardIcon sx={{ width: 18, height: 18 }} />
            </IconButton>
          }
          onClick={toggleModal}
        >
          Top Up
        </Button>
      </MenuItem>

      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MarkEmailUnreadOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar
            sx={{ width: 28, height: 28 }}
            alt={store.userReducer.userDetails.business_name}
            src={store.userReducer.userDetails.logo}
          />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Button
          className={classes.topUpButton}
          size="small"
          startIcon={
            <IconButton
              sx={{
                backgroundColor: "#B55FA5",
                color: "#fff",
                width: 24,
                height: 24,
              }}
            >
              <PowerSettingsNewIcon sx={{ width: 18, height: 18 }} />
            </IconButton>
          }
        >
          Logout
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar className="MerchantToolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => props.handleDrawerToggle()}
          edge="start"
          sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            className={classes.topUpButton}
            size="small"
            startIcon={
              <IconButton
                sx={{
                  backgroundColor: "#B55FA5",
                  color: "#fff",
                  width: 24,
                  height: 24,
                }}
              >
                <ArrowUpwardIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
            }
            onClick={toggleModal}
          >
            Top Up
          </Button>
          {/* <IconButton
            size="large"
            aria-label="show 3 new notifications"
            color="inherit"
          >
            <Badge badgeContent={3} color="error">
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <MarkEmailUnreadOutlinedIcon />
            </Badge>
          </IconButton> */}

          <IconButton
            size="small"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleLogoutMenuOpen}
            color="inherit"
          >
            <Avatar
              sx={{ width: 28, height: 28 }}
              alt={store.userReducer.userDetails.business_name}
              src={store.userReducer.userDetails.logo}
            />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={logoutAnchorEl}
            open={isLogoutMenu}
            onClose={handleLogoutMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>
        <Modal
          open={modalOpen}
          onClose={toggleModal}
          className={classes.paymentModal}
        >
          <Paper className={classes.paymentPaper}>
            {/* payment process */}
            <Elements stripe={stripePromise}>
              <CheckoutForm
                toggleModal={toggleModal}
                toggleSuccessModal={toggleSuccessModal}
              />
            </Elements>
          </Paper>
        </Modal>
        <Modal
          open={successModal}
          onClose={toggleSuccessModal}
          className={classes.successModal}
        >
          <Paper className={classes.successPaper}>
            <Image src={successIcon} />
            <Typography className={classes.mainText}>Top Up Done!</Typography>
            <Typography style={{ margin: "5% 0" }}>
              {store.userReducer.paymentMsg}
            </Typography>
            <Button className={classes.button} href={"/userDetails"}>
              Ok
            </Button>
          </Paper>
        </Modal>
      </Toolbar>

      {renderMobileMenu}
    </Box>
  );
}
