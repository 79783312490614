import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Link,
  ButtonGroup,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Logo from "../assets/images/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    padding: "5%",
    paddingBottom: "2%",
    backgroundColor: theme.palette.footerWhite.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "200%",
    color: theme.palette.defaultBlack.main,
    lineHeight: "150%",
  },
  linksRow: {
    marginRight: "5%",
    marginTop: "2%",
  },
  logo: {
    marginTop: "5%",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
  },
  icon: {
    fontSize: "300%",
    color: theme.palette.secondary.main,
    margin: "2%",
  },
  iconGroup: {
    width: "60%",
  },
  footerContainer: {
    width: "100%",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.footerContainer}>
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid>
            <Typography className={classes.mainText}>EXPLORE</Typography>
          </Grid>
          <Grid container>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Home
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Who We Are
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="/#products" className={classes.captionText}>
                Products
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="/contactus" className={classes.captionText}>
                Contact Us
              </Link>
            </Grid>
            {/* Hided following items until further notice */}
            {/* <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Pricing
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Company
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Careers
              </Link>
            </Grid> */}
          </Grid>
          <Grid container className={classes.logo}>
            <Grid item style={{ width: "15%" }}>
              <Image src={Logo} />
            </Grid>
            <Grid item style={{ marginLeft: "2%" }}>
              <Grid item className={classes.captionText}>
                {/* All rights reserved */}
                © 2023 Ding Fung Aluminum Windows and Doors Service Limited. All rights reserved.
              </Grid>
              <Grid item>
                <ButtonGroup
                  variant="text"
                  color="secondary"
                  aria-label="text primary button group"
                >
                  <Button className={classes.buttonText} href="/termsConditions">
                    Terms of Use
                  </Button>
                  <Button className={classes.buttonText} href="/privacyPolicy">
                    Privacy Policy
                  </Button>
                  {/* Hide cookies */}
                  {/* <Button className={classes.buttonText} href="#">
                    Cookies
                  </Button> */}
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item className={classes.iconGroup}>
              <Grid container justify="flex-end">
                <Grid item className={classes.icon}>
                  <FacebookIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <TwitterIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <InstagramIcon fontSize="large" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
