import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import TestimonialImageOverlay from "./reusableComponents/TestimonialImageOverlay";
// import testimonialImg from '../assets/images/testimonial-img.svg';
import testimonialImg from "../assets/images/success-icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "20%",
      // minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
    justifyContent: "center",
    // minHeight: "70vh",
    padding: "5%",
    backgroundColor: theme.palette.primary.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
    textAlign: "center",
  },
}));

const Testimonials = () => {
  const classes = useStyles();

  return (
    <Hidden mdDown>
      <Grid className={classes.root}>
        <Typography gutterBottom className={classes.mainText}>
          Testimonials
        </Typography>
        <Grid className={classes.sectionContainer}>
          <TestimonialImageOverlay
            image={"https://eshendetesia.com/images/user-profile.png"}
            name={"Jessica Jonas"}
            title={"Resturant Owner"}
            testimonial={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          />
          <TestimonialImageOverlay
            image={
              "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            }
            name={"Jessica Jonas"}
            title={"Resturant Owner"}
            testimonial={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          />
          <TestimonialImageOverlay
            image={"https://eshendetesia.com/images/user-profile.png"}
            name={"Jessica Jonas"}
            title={"Resturant Owner"}
            testimonial={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          />
          <TestimonialImageOverlay
            image={"https://eshendetesia.com/images/user-profile.png"}
            name={"Jessica Jonas"}
            title={"Resturant Owner"}
            testimonial={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          />
        </Grid>
      </Grid>
    </Hidden>
  );
};

export default Testimonials;
