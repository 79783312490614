import React, { useEffect } from "react";
import MerchantDrawer from "../components/merchantDashboard/MerchantDrawer";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MerchantDashboard = () => {
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      history.push("/");
    }
  }, []);

  return (
    <Grid container>
      <MerchantDrawer />
    </Grid>
  );
};

export default MerchantDashboard;
