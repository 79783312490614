import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Card,
  CardContent,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import one from "../assets/images/products/icons/one.svg";
import two from "../assets/images/products/icons/two.svg";
import three from "../assets/images/products/icons/three.svg";
import four from "../assets/images/products/icons/four.svg";
import five from "../assets/images/products/icons/five.svg";
import six from "../assets/images/products/icons/six.svg";
import firstIcon from "../assets/images/products/icons/01.svg";
import secondIcon from "../assets/images/products/icons/02.svg";
import thirdIcon from "../assets/images/products/icons/03.svg";
import fourIcon from "../assets/images/products/icons/04.svg";
import fiveIcon from "../assets/images/products/icons/05.svg";
import sixIcon from "../assets/images/products/icons/06.svg";
import mainBg from "../assets/images/main-bg.svg";
import moneyHandIcon from "../assets/images/money-hand-icon.svg";
import { Calculate } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    // minHeight: "100vh",
  },
  firstSection: {
    [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column",
      //   paddingTop: "20%",
      //   // height:'100%',
      //   minHeight: "80vh",
      //   alignItems: "center",
      //   textAlign: "center",
      //   width: "100%",
    },
    justifyContent: "center",
    // minHeight: "100vh",
    backgroundSize: "cover",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${mainBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    padding: "20px",
  },
  secondSection: {
    padding: "20px",
  },
  thirdSection: {
    padding: "20px",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },

  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
  },
  uniqueText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    padding: "50px 0px",
  },
  uniqueGrid: {
    alignSelf: "center",
  },
  stepCardsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    margin: "50px 0px 50px 0px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 0px 10px 0px",
    },
  },
  productCards: {
    borderTopRightRadius: "40px",
    // maxWidth: "250px",
    height: "200px",
  },
  productCardsHeading: {
    paddingBottom: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  stepCardsItem: {
    position: "relative",
    width: "calc(100% * (1/4) - 10px - 1px)",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      margin: "auto",
      marginBottom: "20px",
    },
  },
  numbers: {
    position: "absolute",
    right: "-30px",
    top: "0",
  },
}));

const ProductSteps = () => {
  const classes = useStyles();
  const firstRow = [
    {
      icon: one,
      topIcon: firstIcon,
      heading: "Save Spending",
      description: "Advertising expense is directly linked to actual result.",
    },
    {
      icon: two,
      topIcon: secondIcon,
      heading: "Flexible",
      description: "Almost all advertising details are highly customizable.",
    },
    {
      icon: three,
      topIcon: thirdIcon,
      heading: "Quick and Easy",
      description: "It only takes 1-minute to create an advertisement.",
    },
  ];
  const secondRow = [
    {
      icon: four,
      topIcon: fourIcon,
      heading: "High Accurancy",
      description: "Specific time, Specific people, Precise location.   ",
    },
    {
      icon: five,
      topIcon: fiveIcon,
      heading: "Mutual Platform",
      description: `Business-to-Consumer (B2C), 
      Consumer-to-business (C2B)`,
    },
    {
      icon: six,
      topIcon: sixIcon,
      heading: "Reward Redemption",
      description: "Redeem rewards with accumulated points",
    },
  ];
  return (
    <>
      <Grid container className={classes.sectionContainer}>
        <Grid className={classes.uniqueGrid} item>
          <Typography gutterBottom className={classes.uniqueText} variant="h6">
            6 Unique Competitive Advantage
          </Typography>
        </Grid>
        <div className={classes.stepCardsContainer}>
          {firstRow.map((data, i) => {
            return (
              <Grid className={classes.stepCardsItem} item key={i}>
                <Card className={classes.productCards} sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Grid>
                      <Image src={data.icon} />
                    </Grid>

                    <Typography
                      className={classes.productCardsHeading}
                      variant="h6"
                    >
                      {data.heading}
                    </Typography>
                    <Typography className={classes.productCardsDescription}>
                      {data.description}
                    </Typography>
                  </CardContent>
                </Card>
                <Image className={classes.numbers} src={data.topIcon} />
              </Grid>
            );
          })}
        </div>
        <div className={classes.stepCardsContainer}>
          {secondRow.map((data, i) => {
            return (
              <Grid className={classes.stepCardsItem} item key={i}>
                <Card className={classes.productCards} sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Grid>
                      <Image src={data.icon} />
                    </Grid>

                    <Typography
                      className={classes.productCardsHeading}
                      variant="h6"
                    >
                      {data.heading}
                    </Typography>
                    <Typography className={classes.productCardsDescription}>
                      {data.description}
                    </Typography>
                  </CardContent>
                </Card>
                <Image className={classes.numbers} src={data.topIcon} />
              </Grid>
            );
          })}
        </div>
      </Grid>
    </>
  );
};

export default ProductSteps;
