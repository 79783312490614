import Axios from "axios";
import { getToken } from "./getToken";

Axios.defaults.baseURL = process.env.REACT_APP_ADMIN_BASE_URL;

Axios.defaults.headers.post["Content-Type"] = "application/json";

export const axiosInstance = Axios.create({});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
