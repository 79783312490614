import { axiosInstance } from "../middleware/Instance";
import {
  GET_BOOKMARK_VALUE,
  GET_CLAIMED_COUPON,
  GET_SERVICE_PROVIDED,
  GET_NEARBY_USER,
  GET_POINTS_HISTORY,
  GET_MERCHANT_POINTS
} from "./Types";

export const getBookMarkedValue = () => async (dispatch) => {
  axiosInstance
    .get("/api/merchant/totalBookmarkedCount/")
    .then((response) => {
      dispatch({ type: GET_BOOKMARK_VALUE, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};

export const getClaimedCoupon = () => async (dispatch) => {
  axiosInstance
    .get("/api/merchant/totalClaimedCoupons/")
    .then((response) => {
      dispatch({ type: GET_CLAIMED_COUPON, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};

export const getServiceProvided = () => async (dispatch) => {
  axiosInstance
    .get("/api/merchant/servicesProvidedCount/")
    .then((response) => {
      dispatch({ type: GET_SERVICE_PROVIDED, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};

export const getNearbyUser = () => async (dispatch) => {
  axiosInstance
    .get("/api/user/nearbyUserRequests/")
    .then((response) => {
      dispatch({ type: GET_NEARBY_USER, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};

export const getMerchantPoints = () => async (dispatch) => {
  axiosInstance
    .get("/api/merchant/merchantPoints/")
    .then((response) => {
      dispatch({ type: GET_MERCHANT_POINTS, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};

export const getMerchantPointHistory = () => async (dispatch) => {
  axiosInstance
    .get("/api/merchant/merchantpointshistory/")
    .then((response) => {
      dispatch({ type: GET_POINTS_HISTORY, payload: response.data });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/";
      }
    });
};
