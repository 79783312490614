import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import mainImg from "../assets/images/main-img.svg";
import mainBg from "../assets/images/main-bg.svg";
import LogIn from "../components/LogIn";
import Footer from "../components/Footer";
import LandingPageAppBar from "../components/LandingPageAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "20%",
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
    justifyContent: "center",
    // height: '100%',
    minHeight: "100vh",
    padding: "5% 0",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${mainBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultBlack.main,
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    paddingLeft: "5%",
    paddingTop: "5%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
      // width: '100%'
    },
  },
  ImageSection: {
    display: "flex",
    alignItems: "flex-start",
    // margin: '5%',
    //padding: '5%',
    [theme.breakpoints.down("sm")]: {
      //marginTop: 10,
      // order: 2,
    },
    //marginTop: "60px",
  },
  DashImage: {
    // height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
    //margin: "auto",
    //position: "absolute",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultBlack.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "200px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "10px",
    // border: '1px solid white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultBlack.main,
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.secondary.main,
    lineHeight: "120%",
  },
  videoStyles: {
    width: "100%",
    // height: '80%',
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <Grid>
      <LandingPageAppBar />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <LogIn />
            {/* If you dont have acccount. You can <a href='#'>Register Here.</a> */}
          </Grid>
          <Grid item xs={12} md={6} className={classes.ImageSection}>
            {/* <video src={video} controls="controls" loop='loop' autoplay="autoplay" className={classes.videoStyles}/> */}
            {/* <iframe width="100%" height="80%" src="https://www.youtube.com/embed/668nUCeBHyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <Image
              // fluid
              src={mainImg}
              className={classes.DashImage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Main;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { Grid } from "@material-ui/core";
// import LogIn from '../components/LogIn';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.primary.main,
//     padding: 0,
//     margin: 0
//   },
// }));

// const LandingPage = () => {
//   const classes = useStyles();
//   return (
//     <Grid className={classes.root}>
//       <LogIn />
//     </Grid>
//   );
// };

// export default LandingPage;
